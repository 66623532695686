<template>
  <v-dialog
    v-model="dialogContasPagas"
    @click:outside="fecharContasPagas"
    @keydown.esc="fecharContasPagas"
    width="90vw"
    scrollable
  >
    <v-card>
      <v-card-title class="pa-0">
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="px-6 py-8">
        <ListContasReceberPagas />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ContasPagasDialog",

  props: {
    dialogContasPagas: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ListContasReceberPagas: () => import("./ListContasReceberPagas.vue"),
  },

  data() {
    return {
      loading: false,
      tab: 0,
      dialogBaixaVenda: false,
    };
  },

  computed: {},

  methods: {
    fecharContasPagas() {
      this.$emit("update:dialogContasPagas", false);
      this.$emit("fetch-caixas-items");
    },
  },

  mounted() {},
};
</script>

<style></style>
